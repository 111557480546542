import { type LoaderFunctionArgs, data } from '@remix-run/node'
import {
  Links,
  Meta,
  type MetaArgs,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
  useRouteLoaderData
} from '@remix-run/react'
import { orgMutations } from '@repo/db/mutations/organization'
import { userMutations } from '@repo/db/mutations/user'
import { Toaster } from '@repo/ui/components/Toaster.js'
import { TooltipProvider } from '@repo/ui/components/Tooltip'
import styles from '@repo/ui/tailwind.css?url'
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix'
import { ErrorCard } from './components/ErrorCard'
import { useSentry } from './hooks/useSentry'
import { useToaster } from './hooks/useToaster'
import { authenticator } from './services/auth.server'
import { getIntercomHash } from './services/intercom.server'
import { getCookie } from './utils/cookie.server'
import { env } from './utils/env.server'
import { getToast } from './utils/toast.server'

declare global {
  interface Window {
    ENV: Awaited<ReturnType<typeof loader>>['data']['ENV']
  }
}

export const links = () => [{ rel: 'stylesheet', href: styles }]

export const meta = ({ data }: MetaArgs<typeof loader>) => {
  return [{ title: `${data?.ENV.CONSTANTS.APP_NAME} Pulse` }]
}

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const { cookie, toast } = await getToast(request)
  const activeOrg = await getCookie(request, 'activeOrg')
  const auth = await authenticator.getAuthSession(request)

  const [user, org] = await Promise.all([
    auth
      ? userMutations.getAuthenticatedUser(auth.userId, activeOrg?.orgId)
      : null,
    activeOrg ? orgMutations.getById(activeOrg.orgId) : null
  ])

  return data(
    {
      ENV: {
        APP_ENV: env.APP_ENV,
        CONSTANTS: env.CONSTANTS,
        DEMO_MODE: env.DEMO_MODE,
        INTERCOM_APP_ID: env.INTERCOM_APP_ID,
        INTERCOM_HASH: getIntercomHash(auth?.userId),
        SENTRY_DSN: env.SENTRY_DSN
      },
      toast,
      authenticatedUser: user,
      authenticatedOrg: org,
      activeOrg
    },
    { headers: { 'set-cookie': cookie } }
  )
}

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useRouteLoaderData<typeof loader>('root')

  useSentry()

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        {data ? (
          <script
            dangerouslySetInnerHTML={{
              __html: `window.ENV = ${JSON.stringify(data.ENV)}`
            }}
          />
        ) : null}
      </head>
      <body className="font-sans antialiased text-sm text-primary">
        <TooltipProvider delayDuration={400}>{children}</TooltipProvider>
        <Toaster />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}

export function ErrorBoundary() {
  const error = useRouteError()
  captureRemixErrorBoundaryError(error)

  return (
    <div className="h-dvh flex flex-col items-center justify-center bg-secondary">
      <ErrorCard error={error} />
    </div>
  )
}

function App() {
  const { toast } = useLoaderData<typeof loader>()
  useToaster(toast)

  return (
    <>
      <Outlet />
      <Toaster />
    </>
  )
}

export default withSentry(App)
